import { createContext, ReactNode, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { WorkerAttendancePermitStatusEnum } from 'interfaces/enums';
import { getDashboardData, getVouchers } from 'server/requests';
import { useAppContext } from 'context';
import { VoucherProps } from 'interfaces/requests';

interface ContextProps {
  homeInformation: HomeScreenDataType;
  loading: boolean;
  vouchers: Array<VoucherProps>;
  getInitialData: VoidFunction;
}

interface GlobalInformationProviderProps {
  children: ReactNode;
}

type HomeScreenDataType = {
  permits: Array<{ _id: string; type: { code: string; name: string }; status: WorkerAttendancePermitStatusEnum }>;
  counters: { delay: number; overtime: number };
};

const GlobalInformationContext = createContext({} as ContextProps);

export const useGlobalInformationContext = () => useContext(GlobalInformationContext);

const GlobalInformationProvider = ({ children }: GlobalInformationProviderProps) => {
  const { user, headquarter } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [vouchers, setVouchers] = useState<Array<VoucherProps>>([]);
  const [homeInformation, setHomeInformation] = useState<HomeScreenDataType>({ permits: [], counters: { delay: 0, overtime: 0 } });

  useLayoutEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    try {
      setLoading(true);
      if (!user || !headquarter) return;
      const [voucherList, dashboardData] = await Promise.all([
        getVouchers(user._id!, headquarter._id!),
        getDashboardData({ company: headquarter.company, headquarter: headquarter._id, worker: user._id }),
      ]);
      setVouchers([...voucherList]);
      setHomeInformation({ ...dashboardData });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const values: ContextProps = useMemo(() => ({ homeInformation, loading, vouchers, getInitialData }), [homeInformation, loading, vouchers]);

  return <GlobalInformationContext.Provider value={values}>{children}</GlobalInformationContext.Provider>;
};

export default GlobalInformationProvider;
