import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useAppContext } from 'context';
import useNotification from 'hooks/useNotification';
import { useState } from 'react';
import { acceptPrivacyPolicy } from 'server/requests';

const PrivacyPolicy = () => {
  const message = useNotification();
  const { user } = useAppContext();
  const [loading, setLoading] = useState(false);

  const handleAccept = async () => {
    try {
      setLoading(true);

      await acceptPrivacyPolicy({ worker: String(user?._id) });

      message({
        title: 'Éxito',
        description: 'Usted aceptó las políticas de privacidad y condifencialidad de los datos personales',
        type: 'success',
      });

      window.location.reload();
    } catch (error) {
      message({ title: 'Ocurrió un error inesperado', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle textAlign="center" variant="h5" fontWeight={700}>
        PRIVACIDAD Y CONFIDENCIALIDAD DE LOS DATOS PERSONALES NAPCOLABORADORES
        <Typography color="text.secondary">Última actualización: 09 de enero de 2020</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          En cumplimiento con la Ley N° 29733, reglamentado por el Decreto Supremo N° 003-2013- JUS; mediante el presente documento le informa que sus
          datos de carácter personal se encuentran protegidos por el Responsable del área administrativa y contable denominado; PERSONAL Y RECURSOS
          HUMANOS, de la Institución a la que usted labora; estos datos los facilita voluntariamente para establecer una relación laboral con esta
          institución, siendo necesarios para el mantenimiento y cumplimiento de la relación laboral, así como para llevar a cabo la gestión del
          personal contable y administrativa de esta institución.
        </Typography>
        <Typography mt={2}>
          <b>1. OBJETIVO.- </b> De conformidad con la Ley N° 29733, reglamentado por el Decreto Supremo N° 003-2013- JUS, le informamos que sus datos
          personales obran en poder de la Institución donde labora y a los cuales se accede a través de esta plataforma, sus datos serán almacenados
          en los servidores internacionales de AMAZON WEB SERVICE, con el único propósito de procesar los datos y que la plataforma funcione
          adecuadamente.
        </Typography>
        <Typography pl={2} variant="body2">
          1.1. Para comunicarnos con usted.
        </Typography>
        <Typography pl={2} variant="body2">
          1.2. Para hacer llegar beneficios sociales como entrega de boletas de remuneraciones, papeletas de CTS, control de asistencia, entre otros
          relacionados con su empleo
        </Typography>
        <Typography pl={2} variant="body2">
          1.3. Para comunicar las políticas de la institución
        </Typography>
        <Typography pl={2} variant="body2">
          1.4. Otros que la institución estime conveniente.
        </Typography>

        <Typography mt={2}>
          <b>2. ALCANCE.- </b> La presente política es de aplicación para todas las páginas web y aplicaciones administradas por la Institución y
          estén al uso público.
        </Typography>

        <Typography mt={2}>
          <b>3. REFERENCIAS NORMATIVAS.- </b> La presente política es de aplicación para todas las páginas web y aplicaciones administradas por la
          Institución y estén al uso público.
        </Typography>
        <Typography pl={2} variant="body2">
          3.1. Ley N° 29733 – Ley de Protección de Datos Personales.
        </Typography>
        <Typography pl={2} variant="body2">
          3.2. D.S. N° 003-2013-JUS – Reglamento de la Ley N° 29733.
        </Typography>

        <Typography mt={2}>
          <b>4. DE LOS PRINCIPIOS RECTORES.- </b> La Institución declara que respeta los principios de protección de datos personales.
        </Typography>
        <Typography pl={2} variant="body2">
          <b>4.1. Principio de legalidad</b>
          <br />
          El tratamiento de los datos personales se hace conforme a lo establecido en la ley. Se prohíbe la recopilación de los datos personales por
          medios fraudulentos, desleales o ilícitos.
        </Typography>
        <Typography pl={2} variant="body2">
          <b>4.2. Principio de consentimiento</b>
          <br />
          Para el tratamiento de los datos personales debe mediar el consentimiento de su titular.
        </Typography>
        <Typography pl={2} variant="body2">
          <b>4.3. Principio de finalidad</b>
          <br />
          Los datos personales deben ser recopilados para una finalidad determinada, explícita y lícita. El tratamiento de los datos personales no
          debe extenderse a otra finalidad que no haya sido la establecida de manera inequívoca como tal al momento de su recopilación, excluyendo los
          casos de actividades de valor histórico, estadístico o científico cuando se utilice un procedimiento de disociación o anonimización.
        </Typography>
        <Typography pl={2} variant="body2">
          <b>4.4. Principio de proporcionalidad</b>
          <br />
          Todo tratamiento de datos personales debe ser adecuado, relevante y no excesivo a la finalidad para la que estos hubiesen sido recopilados.
        </Typography>
        <Typography pl={2} variant="body2">
          <b>4.5. Principio de calidad</b>
          <br />
          Los datos personales que vayan a ser tratados deben ser veraces, exactos y, en la medida de lo posible, actualizados, necesarios,
          pertinentes y adecuados respecto de la finalidad para la que fueron recopilados. Deben conservarse de forma tal que se garantice su
          seguridad y solo por el tiempo necesario para cumplir con la finalidad del tratamiento.
        </Typography>
        <Typography pl={2} variant="body2">
          <b>4.6. Principio de seguridad</b>
          <br />
          El titular del banco de datos personales y el encargado de su tratamiento deben adoptar las medidas técnicas, organizativas y legales
          necesarias para garantizar la seguridad de los datos personales. Las medidas de seguridad deben ser apropiadas y acordes con el tratamiento
          que se vaya a efectuar y con la categoría de datos personales de que se trate.
        </Typography>
        <Typography pl={2} variant="body2">
          <b>4.7. Principio de disposición de recurso</b>
          <br />
          Todo titular de datos personales debe contar con las vías administrativas o jurisdiccionales necesarias para reclamar y hacer valer sus
          derechos, cuando estos sean vulnerados por el tratamiento de sus datos personales.
        </Typography>
        <Typography pl={2} variant="body2">
          <b>4.8. Principio de nivel de protección adecuado</b>
          <br />
          Para el flujo transfronterizo de datos personales, se debe garantizar un nivel suficiente de protección para los datos personales que se
          vayan a tratar o, por lo menos, equiparable a lo previsto por esta Ley o por los estándares internacionales en la materia.
        </Typography>

        <Typography mt={2}>
          <b>5. DE LA DEFINICIÓN DE DATOS PERSONALES</b>
        </Typography>
        <Typography variant="body2">
          De acuerdo con la ley, se define el término Datos Personales como “aquella información numérica, alfabética, gráfica, fotográfica, acústica,
          sobre hábitos personales, o de cualquier otro tipo concerniente a las personas naturales que las identifica o las hace identificables a
          través de medios que puedan ser razonablemente utilizados.” La Institución considera como datos personales, a la información que poseemos y
          a toda aquella información que el Usuario ingrese voluntariamente a través de cualquiera de nuestros formularios en nuestros sitios web,
          plataforma o la que se envía por correo electrónico.
        </Typography>

        <Typography mt={2}>
          <b>6. DE LA TITULARIDAD DE LA BASE DE DATOS</b>
        </Typography>
        <Typography variant="body2">
          Los datos personales que puedan ser suministrados a través del sitio web u otro medio serán incorporados a los bancos de datos bajo la
          titularidad de la Institución.
        </Typography>

        <Typography mt={2}>
          <b>7. DE LAS FINALIDADES DEL TRATAMIENTO DE LA INFORMACIÓN</b>
        </Typography>
        <Typography variant="body2">
          De conformidad con la Ley N° 29733 - Ley de Protección de Datos Personales y su Reglamento aprobado mediante D.S. 003-2013-JUS, el
          interesado otorgará su consentimiento expreso para que los datos personales que facilite queden incorporados en el Banco de Datos Personales
          de Personas Interesadas en la Institución y sean tratados por esta con la finalidad de absolver sus consultas y brindarles información
          publicitaria, dándoles usos que incluyen temas referidos al análisis de perfiles, publicidad y prospección comercial, fines estadísticos,
          históricos, científicos y educación. El usuario autoriza a que Institución mantenga sus datos personales en el banco de datos referido en
          tanto sean útiles para la finalidad y usos antes mencionados.
        </Typography>

        <Typography mt={2}>
          <b>8. DE LA DECLARACIÓN DE PRIVACIDAD</b>
        </Typography>
        <Typography variant="body2">
          La aplicación no recopila datos personales sobre el Usuario, excepto cuando él mismo brinde información voluntariamente, al registrarse en
          alguno de los sitios web o cuando envíe un correo electrónico u otra comunicación dirigida a la Institución. La aplicación no procesará ni
          transferirá los datos personales sin previo consentimiento del Usuario.
        </Typography>

        <Typography mt={2}>
          <b>9. DE LA SEGURIDAD Y CONFIDENCIALIDAD DE LOS DATOS</b>
        </Typography>
        <Typography variant="body2">
          La institución informa a sus usuarios que la aplicación se compromete a cumplir con los estándares de seguridad y confidencialidad
          necesarios para asegurar la confiabilidad, integridad y disponibilidad de la información recopilada de los usuarios. El Usuario es el único
          responsable de suministrar sus datos personales a la institución. y este sea utilizado en esta aplicación. Asimismo, el usuario autoriza que
          las imágenes en fotografía o video que opte por cargar en esta aplicación, también serán objeto de flujo transfronterizo. La institución.
          procurará que estos no se vean afectados por cualquier uso indebido.
        </Typography>

        <Typography mt={2}>
          <b>10. DE LOS DERECHOS DE LOS USUARIOS</b>
        </Typography>
        <Typography variant="body2">
          De conformidad con la Ley, en caso que el usuario desee ejercer sus derechos de revocatoria de consentimiento, modificación o cualquier otro
          que se encuentra contemplado en la legislación de la materia, podrá solicitarlo a secretaria de la Institución o vía correo
          netplataformaperusac@gmail.com con asunto: DATOS (indicando la Institución donde trabaja).
        </Typography>

        <Typography mt={2}>
          <b>11. DEL CONSENTIMIENTO DEL USUARIO Y ACEPTACIÓN DE LOS TÉRMINOS</b>
        </Typography>
        <Typography variant="body2">
          Esta declaración de privacidad y confidencialidad descrita en la presente política constituye un acuerdo válido entre el Usuario y la
          Institución, que confirma el conocimiento, entendimiento y aceptación por parte del Usuario de lo expuesto con los fines expresados. En caso
          no estar de acuerdo, el usuario no deberá proporcionar ninguna información personal, ni utilizar el servicio o cualquier información
          relacionada con los sitios web o la aplicación suministrada
        </Typography>

        <Typography mt={2}>
          <b>12. ACCESOS Y CONTRASEÑAS:</b>
        </Typography>
        <Typography variant="body2">
          Si accede a los equipos informáticos, cada trabajador dispondrá de una clave de acceso propia e intransferible para acceder a la PLATAFORMA;
          Esta clave el usuario deberá cambiar periódicamente para garantizar la confidencialidad. La nueva contraseña de preferencia tiene que ser
          alfanumérica, es decir, estar compuesta de letras y números. Esta contraseña tiene que tener una longitud mínima de 6 dígitos.
          <br />
          Si su labor en la institución es con equipos informáticos, será responsable de la custodia y utilización del ordenador que le haya sido
          asignado.
          <br />
          Los puestos de trabajo estarán bajo la responsabilidad de un usuario autorizado, como encargado, administrador(a), etc., que garantizará que
          la información que muestran los sistemas de información no pueda ser visibles para personas no autorizadas, siendo cada trabajador
          responsable de la custodia y utilización de su puesto de trabajo, mediante las claves correspondientes que a tal efecto se asignarán para
          cada trabajador, por lo tanto usted es responsable de la confidencialidad y custodia de su contraseña.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <LoadingButton variant="contained" size="large" loading={loading} onClick={handleAccept} disabled={!user}>
          ACEPTAR
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PrivacyPolicy;
