import { Box, CircularProgress, circularProgressClasses } from '@mui/material';

const Loader = () => {
  return (
    <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
      <CircularProgress variant="determinate" sx={{ color: (theme) => theme.palette.grey[400] }} size={40} thickness={4} value={100} />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: 'primary.main',
          animationDuration: '500ms',
          position: 'absolute',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
      />
    </Box>
  );
};

export default Loader;
