import Spin from 'components/Spinner';
import { HeadquarterProps, UserProps } from 'interfaces/requests';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import StorageService from 'server/StorageService';
import { verifyToken } from 'server/requests';
import { HEADQUARTER_SESSION_KEY, USER_SESSION_KEY } from 'utils/constants';
import { routeConfig } from 'routes';
import HeadquarterModal from 'components/HeadquarterModal';
import PrivacyPolicy from 'components/PrivacyPolicy';

interface ContextProps {
  user: null | UserProps;
  setUser: Dispatch<SetStateAction<null | UserProps>>;
  headquarter: null | HeadquarterProps;
  setHeadquarter: Dispatch<SetStateAction<null | HeadquarterProps>>;
  headquarters: Array<HeadquarterProps>;
  setHeadquarters: Dispatch<SetStateAction<Array<HeadquarterProps>>>;
  closeSession: VoidFunction;
}

interface AppProviderProps {
  children: ReactNode;
}

const AppContext = createContext({} as ContextProps);

export const useAppContext = () => useContext(AppContext);

const privateRoutes = routeConfig.find((item) => item.path === 'private')?.children?.map((item) => `/private/${item.path}`);
const AppProvider = ({ children }: AppProviderProps) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<null | UserProps>(null);
  const [headquarter, setHeadquarter] = useState<null | HeadquarterProps>(null);
  const [headquarters, setHeadquarters] = useState<Array<HeadquarterProps>>([]);

  useLayoutEffect(() => {
    verifyUser();
  }, []);

  const closeSession = () => {
    StorageService.remove(USER_SESSION_KEY);
    StorageService.remove(HEADQUARTER_SESSION_KEY);
    window.location.href = '/login';
  };

  const verifyUser = async () => {
    try {
      setLoading(true);

      if (!privateRoutes?.includes(location.pathname)) return;

      const token = StorageService.get(USER_SESSION_KEY);
      if (!token) throw new Error('Unauthorized');
      const res: UserProps = await verifyToken();
      if (!res) throw new Error('Unauthorized');
      setUser({ ...res });
      setHeadquarters([...res.headquarter]);

      const savedHeadquarter = StorageService.get(HEADQUARTER_SESSION_KEY);
      if (!savedHeadquarter) return;
      const finder = res.headquarter.find((item) => item._id === savedHeadquarter);
      if (!finder) return;
      setHeadquarter(finder);
    } catch (error) {
      closeSession();
    } finally {
      setLoading(false);
    }
  };

  const values: ContextProps = useMemo(
    () => ({ user, setUser, headquarter, setHeadquarter, headquarters, setHeadquarters, closeSession }),
    [user, headquarter, headquarters]
  );

  const validations = useMemo(() => {
    const vars = { privacyPolicy: false, selectHeadquarter: false };
    if (privateRoutes?.includes(location.pathname)) {
      vars.selectHeadquarter = !headquarter;
      vars.privacyPolicy = !user?.acceptPrivacyPolicy;
    }
    return vars;
  }, [headquarter, location.pathname, user]);

  console.log('user', user?.acceptPrivacyPolicy);

  return (
    <AppContext.Provider value={values}>
      {loading ? (
        <Spin />
      ) : (
        <div className="not__really">
          {validations.privacyPolicy ? (
            <PrivacyPolicy />
          ) : (
            <div className="not__really__children__lorem__ipsum__dolor__sit__amet">
              {validations.selectHeadquarter ? <HeadquarterModal /> : children}
            </div>
          )}
        </div>
      )}
    </AppContext.Provider>
  );
};

export default AppProvider;
