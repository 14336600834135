import { AppBar, Box, Card, ListItemButton, ListItemIcon, Paper, Toolbar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { APPBAR_HEIGHT, DRAWER_WIDTH, APPBAR_PADDING } from 'utils/constants';

export const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));

export const CustomScrollBar = styled('div')(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: 5,
    height: 5,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.dark,
    borderRadius: 5,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.primary.main,
  },
  '&::-webkit-scrollbar-thumb:active': {
    backgroundColor: theme.palette.primary.light,
  },
  '&::-webkit-scrollbar-track': {
    background: '#BBB',
    borderRadius: 5,
  },
  '&::-webkit-scrollbar-track:hover': {
    background: '#AAA',
  },
  '&::-webkit-scrollbar-track:active': {
    background: '#CCC',
  },
}));

export const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_HEIGHT + 10,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.shadows[10],
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_HEIGHT,
    padding: theme.spacing(0, 5),
  },
}));

export const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  borderBottom: '1px solid',
  borderColor: theme.palette.action.disabledBackground,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

export const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_HEIGHT,
    padding: theme.spacing(0, 5),
  },
}));

export const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: '#ffffff',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: '#aaaaaa',
  },
}));

export const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const RootStyleSidebar = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

export const SidebarBase = styled(Box)(({ theme }) => ({
  height: '100vh',
  backgroundColor: theme.palette.primary.main,
}));

export const AccountStyle = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: theme.spacing(0, 2.5),
  gap: 10,
  marginTop: 15,
  marginBottom: 10,
}));

export const RootStylePrivateLayout = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

export const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APPBAR_HEIGHT + APPBAR_PADDING,
  paddingBottom: APPBAR_PADDING,
  paddingLeft: APPBAR_PADDING,
  paddingRight: APPBAR_PADDING,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APPBAR_HEIGHT + APPBAR_PADDING,
  },
}));

export const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

export const LoginRootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export const LoginHeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

export const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export const ProgressBar = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 9999,
  width: '100%',
});

export const WrapperInformation = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(4),
  paddingTop: theme.spacing(8),
  position: 'relative',
  marginTop: 0,
  height: '100%',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-100px',
    left: '2px',
    width: '99%',
    height: '250px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    zIndex: -1,
  },
}));

export const ImageRounded = styled('img')(({ theme }) => ({
  width: '150px',
  height: 'auto',
  position: 'absolute',
  top: '-70px',
  left: 'calc((50%) - 75px)',
}));
