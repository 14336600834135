import { Avatar, Dialog, DialogContent, List, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useAppContext } from 'context';
import { HeadquarterProps } from 'interfaces/requests';
import StorageService from 'server/StorageService';
import { HEADQUARTER_SESSION_KEY } from 'utils/constants';

const HeadquarterModal = () => {
  const { headquarters, setHeadquarter } = useAppContext();

  const handleSave = (item: HeadquarterProps) => {
    setHeadquarter(item);
    StorageService.set(HEADQUARTER_SESSION_KEY, item._id);
  };

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <DialogContent>
        <List
          subheader={
            <Typography variant="h5" fontWeight={700}>
              Selecciona una empresa:
            </Typography>
          }
        >
          {headquarters.map((item) => (
            <ListItemButton key={item._id} onClick={() => handleSave(item)}>
              <ListItemAvatar>
                <Avatar src={item.logo} />
              </ListItemAvatar>
              <ListItemText primary={item.description} secondary={`RUC: ${item.ruc}`} />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default HeadquarterModal;
