import { NotificationProps } from 'hooks/useNotification';
import { WorkerAttendancePermitStatusEnum } from 'interfaces/enums';

export const APPBAR_HEIGHT = 65;

export const DRAWER_WIDTH = 280;

export const APPBAR_PADDING = 10;

export const USER_SESSION_KEY = 'F9m28xzmooTZdw4JNlqB7FLNHi5shizD';

export const HEADQUARTER_SESSION_KEY = '8dwnijju953rNv7cUxFTPg9rkQwFOSvg';

export const defaultErrorNotification: NotificationProps = {
  title: 'Ocurrió un error inesperado',
  description: 'Intente mas tarde o comunícate al +51 920994002 explicando el error para una rápida solución.',
  type: 'error',
};

export const TYPE_STUDY = [
  {
    value: 4,
    label: 'INSTITUTOS DE EDUCACIÓN SUPERIOR TECNOLÓGICA (IEST)',
  },
  {
    value: 5,
    label: 'INSTITUTO SUPERIOR PEDAGÓGICO',
  },
  {
    value: 6,
    label: 'UNIVERSIDAD',
  },
  {
    value: 7,
    label: 'EDUCACIÓN SUPERIOR DE FORMACIÓN ARTÍSTICA',
  },
  {
    value: 8,
    label: 'ESCUELAS E INSTITUTOS DE EDUCACIÓN SUPERIOR TECNOLÓGICOS DE LAS FUERZAS ARMADAS Y POLICIALES',
  },
  {
    value: 9,
    label: 'NO ESPECIFICADO (1)',
  },
];

export const TYPE_CAREER = [
  { value: 'CARRERA - PROFESION', label: 'CARRERA - PROFESION' },
  { value: 'POSTGRADO', label: 'POSTGRADO' },
  { value: 'DIPLOMADO', label: 'DIPLOMADO' },
  { value: 'SEMINARIO', label: 'SEMINARIO' },
  { value: 'CURSO', label: 'CURSO' },
  { value: 'TALLER', label: 'TALLER' },
  { value: 'CONVENCIÓN', label: 'CONVENCIÓN' },
  { value: 'SIMPOSIUM', label: 'SIMPOSIUM' },
  { value: 'CHARLA', label: 'CHARLA' },
  { value: 'PARTICIPACIÓN', label: 'PARTICIPACIÓN' },
];

export const privateWorkersItems: Array<{ status: null | boolean; label: string; comment: string }> = [
  {
    status: null,
    label: 'Contar con antecedentes penales y judiciales y/o encontrarme procesado.',
    comment: '',
  },
  {
    status: null,
    label:
      'Haber sido condenado por delito de terrorismo, apología del terrorismo, delito de violación contra la libertad sexual, delitos de corrupción de funcionarios o delito de tráfico ilícito de drogas.',
    comment: '',
  },
  {
    status: null,
    label: 'Haber sido condenado en cualquiera de los delitos previstos en la ley N°29988 y la ley N°30794.',
    comment: '',
  },
  {
    status: null,
    label:
      'Estar comprendido en los delitos detallados en el artículo 2 de la ley N°30901. Ley que implementa un subregistro de condenas y establece la inhabilitación definitiva para desempeñar actividad, profesión, ocupación u oficio que implique el cuidado, vigilancia o atención de niñas, niños o adolescentes.',
    comment: '',
  },
];

export const healthDeclaracionList: Array<{ status: null | boolean; label: string; comment: string }> = [
  {
    label: 'Edad mayor de 65 años.',
    status: null,
    comment: '',
  },
  {
    label: 'Cáncer.',
    status: null,
    comment: '',
  },
  {
    label: 'Enfermedad renal crónica.',
    status: null,
    comment: '',
  },
  {
    label:
      'Enfermedad pulmonar crónica: EPOC(enfermedad pulmonar obstructiva crónica); fibrosis pulmonar; hipertensión pulmonar; asma grave o no controlada.',
    status: null,
    comment: '',
  },
  {
    label: 'Afecciones cardiacas, tales como insuficiencia cardiaca, enfermedad de las arterias coronarias o miocardiopatías.',
    status: null,
    comment: '',
  },
  {
    label: 'Diabetes mellitus, tipo 1 y tipo 2.',
    status: null,
    comment: '',
  },
  {
    label: 'Obesidad (índice de masa corporal (IMC) de 30kg/m2 o más).',
    status: null,
    comment: '',
  },
  {
    label:
      'Personas inmunodeprimidas (sistema inmunitario debilitado) por inmunodeficiencias primarias, uso prolongado de corticosteroides u otros medicamentos inmunosupresores.',
    status: null,
    comment: '',
  },
  {
    label: 'Receptores de trasplante de órganos sólidos o células madre sanguíneas.',
    status: null,
    comment: '',
  },
  {
    label: 'Enfermedad cerebrovascular (infarto o hemorragia cerebral) Hipertensión arterial.',
    status: null,
    comment: '',
  },
  {
    label: 'Hipertensión arterial.',
    status: null,
    comment: '',
  },
  {
    label: 'Síndrome de Down.',
    status: null,
    comment: '',
  },
  {
    label: 'Embarazo.',
    status: null,
    comment: '',
  },
  {
    label: 'Infección por VIH.',
    status: null,
    comment: '',
  },
  {
    label: 'Otros (indicar).',
    status: null,
    comment: '',
  },
];

export const WORKER_ATTENDANCE_PERMITS_TYPE_TRANSLATE = {
  [WorkerAttendancePermitStatusEnum.PENDING]: 'Pendiente',
  [WorkerAttendancePermitStatusEnum.APPROVED]: 'Aprobado',
  [WorkerAttendancePermitStatusEnum.REJECTED]: 'Rechazado',
};

export const monthList = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SETIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
