import { Backdrop } from '@mui/material';
import Loader from './Loader';

interface SpinProps {
  loading: boolean;
}

const Spin = ({ loading }: SpinProps) => (
  <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
    <Loader />
  </Backdrop>
);

Spin.defaultProps = {
  loading: true,
};

export default Spin;
