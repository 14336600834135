import { lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import Loadable from './Loadable';
// layouts
const PrivateLayout = Loadable(lazy(() => import('layouts/Private')));
const PublicLayout = Loadable(lazy(() => import('layouts/Public')));
// private views
const Dashboard = Loadable(lazy(() => import('pages/Private/Dashboard')));
const Vouchers = Loadable(lazy(() => import('pages/Private/Vouchers')));
const Documents = Loadable(lazy(() => import('pages/Private/Documents')));
const CurriculumVitae = Loadable(lazy(() => import('pages/Private/CurriculumVitae')));
const Committee = Loadable(lazy(() => import('pages/Private/Committee')));
const IncomeTax = Loadable(lazy(() => import('pages/Private/IncomeTax')));
const Attendances = Loadable(lazy(() => import('pages/Private/Attendances')));
const Contacts = Loadable(lazy(() => import('pages/Private/Contacts')));
const Profile = Loadable(lazy(() => import('pages/Private/Profile')));
// public views
const Landing = Loadable(lazy(() => import('pages/Public/Landing')));
const Login = Loadable(lazy(() => import('pages/Public/Login')));
const ForgotPassword = Loadable(lazy(() => import('pages/Public/ForgotPassword')));
const RecoveryPassword = Loadable(lazy(() => import('pages/Public/RecoveryPassword')));
// const Page401 = Loadable(lazy(() => import('pages/Others/401')));
const Page404 = Loadable(lazy(() => import('pages/Others/404')));
// ----------------------------------------------------------------------
export const routeConfig: Array<RouteObject> = [
  {
    path: 'private',
    element: <PrivateLayout />,
    children: [
      { path: 'home', element: <Dashboard /> },
      { path: 'vouchers', element: <Vouchers /> },
      { path: 'documents', element: <Documents /> },
      { path: 'cv', element: <CurriculumVitae /> },
      { path: 'committee', element: <Committee /> },
      { path: 'incometax', element: <IncomeTax /> },
      { path: 'attendances', element: <Attendances /> },
      { path: 'contacts', element: <Contacts /> },
      { path: 'profile', element: <Profile /> },
    ],
  },
  {
    path: '',
    element: <PublicLayout />,
    children: [
      { path: '', element: <Landing /> },
      { path: 'login', element: <Login /> },
      { path: 'forgotpassword', element: <ForgotPassword /> },
      { path: 'reset-password/:token', element: <RecoveryPassword /> },
    ],
  },
  { path: '*', element: <Page404 /> },
];

const Router = () => useRoutes(routeConfig);

export default Router;
