import ScrollToTop from 'components/ScrollToTop';
import Router from 'routes';
import ThemeProvider from 'theme';
import dayjs from 'dayjs';
import AppProvider from 'context';
import GlobalInformationProvider from 'context/globalInformation';

const App = () => {
  dayjs.locale('es');

  return (
    <AppProvider>
      <GlobalInformationProvider>
        <ThemeProvider theme={{}}>
          <ScrollToTop />
          <Router />
        </ThemeProvider>
      </GlobalInformationProvider>
    </AppProvider>
  );
};

export default App;
