import { useId } from 'react';

type NotificationTypeEnum = 'error' | 'success' | 'info' | 'warning';

export interface NotificationProps {
  title: string;
  description?: string;
  type: NotificationTypeEnum;
}

const customStyles = {
  error: 'background-color: #FFE7D9; color: #B72136;',
  success: 'background-color: #E9FCD4; color: #229A16;',
  info: 'background-color: #D0F2FF; color: #0C53B7;',
  warning: 'background-color: #FFF7CD; color: #B78103;',
};

const useNotification = () => {
  const uniqueGlobalId = useId();

  const notification = ({ title, type, description }: NotificationProps) => {
    const div = document.createElement('div');
    div.setAttribute('id', uniqueGlobalId);
    div.innerHTML = `
    <div style="position: fixed; top: 10px; left: calc(50% - 150px); z-index: 9999999; width: 350px; box-shadow: 0 0 20px -10px rgba(0,0,0,0.75); border-radius: 8px; padding: 20px; ${
      customStyles[type]
    }">
      <p style="font-weight: 600; font-size: 15px; line-height: 15px; margin: 0;">${title}</p>
      ${
        description
          ? `<p style="font-weight: 400; font-size: 14px; line-height: 15px; margin-top: 10px; color: #555555; margin: 0; margin-top: 10px">${description}</p>`
          : ''
      }
    </div>`;

    document.body.appendChild(div);

    setTimeout(() => {
      document.body.removeChild(div);
    }, 7000);
  };

  return notification;
};

export default useNotification;
