import axios, { InternalAxiosRequestConfig } from 'axios';
import StorageService from './StorageService';
import { USER_SESSION_KEY } from 'utils/constants';

const apiContable = axios.create({
  baseURL: process.env.REACT_APP_API_CONTABLE,
  headers: { 'Content-Type': 'application/json' },
});

const apiWorkers = axios.create({
  baseURL: process.env.REACT_APP_API_WORKERS,
  headers: { 'Content-Type': 'application/json' },
});

const injectToken = async (config: InternalAxiosRequestConfig<any>) => {
  try {
    const token = StorageService.get(USER_SESSION_KEY);
    const newConfig = config;
    newConfig.headers.authorization = token || undefined;
    return newConfig;
  } catch (error) {
    throw new Error('Unauthorized');
  }
};

apiContable.interceptors.request.use(injectToken);
apiWorkers.interceptors.request.use(injectToken);

export { apiContable, apiWorkers };
