import { apiContable, apiWorkers } from 'server';
import { AuthWorkerProps, GetAttendancePermitsParamsProps, IncomeTaxProps, IncomeTaxReqParams, VoucherProps } from 'interfaces/requests';

export const authWorker = async (data: AuthWorkerProps) => {
  const res = await apiContable.post('/workers/auth', data);
  return res.data;
};

export const verifyToken = async () => {
  const res = await apiContable.post('/workers/verify');
  return res.data;
};

export const getVouchers = async (userId: string, headquarterId: string): Promise<Array<VoucherProps>> => {
  const res = await apiContable.get(`/worker/vouchers/${userId}/${headquarterId}`);
  return res.data;
};

export const updateVoucherStatus = async (voucherId: string) => {
  const res = await apiContable.put(`/voucherfile/${voucherId}`);
  return res.data;
};

export const generatePdfWithQR = async (body: { QR_BASE64: string; PDF_URL: string }) => {
  const res = await apiWorkers.post('/files/pdf-with-qr', body);
  return res.data;
};

export const uploadFile = async (formData: FormData) => {
  const res = await apiWorkers.post(`/files/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  return res.data;
};

export const getJobInformationCategory = async (headquarterId: string) => {
  const res = await apiContable.get(`/category/${headquarterId}`);
  return res.data;
};

export const getJobInformation = async (headquarterId: string) => {
  const res = await apiContable.get(`/worker/information/${headquarterId}/general`);
  return res.data;
};

export const getIncidents = async (headquarterId: string, userId: string) => {
  const res = await apiContable.get(`/worker/information/${headquarterId}/incidents/${userId}`);
  return res.data;
};

export const updateDocument = async (userId: string, docId: string, body: Record<string, any>) => {
  const res = await apiContable.put(`/workerfile/${userId}/${docId}`, body);
  return res.data;
};

export const updateWorkerList = async (docId: string, form: Record<string, any>) => {
  const res = await apiContable.put(`/worker/general/information/${docId}`, form);
  return res.data;
};

export const updateUser = async (userId: string, form: Record<string, any>) => {
  const res = await apiContable.put(`/worker/${userId}`, form);
  return res.data;
};

export const deleteUserFormation = async (recordId: string, itemId: string, kind: 'formation' | 'experience' | 'certificate') => {
  const res = await apiContable.delete(`/worker/info/${recordId}/${itemId}/${kind}`);
  return res.data;
};

export const createUserFormation = async (recordId: string, data: Record<string, any>, kind: 'formation' | 'experience' | 'certificate') => {
  const res = await apiContable.put(`/worker/info/${recordId}/${kind}`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  return res.data;
};

export const updateUserFormation = async (
  recordId: string,
  itemId: string,
  data: Record<string, any>,
  kind: 'formation' | 'experience' | 'certificate'
) => {
  const res = await apiContable.put(`/worker/info/${recordId}/${itemId}/${kind}`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  return res.data;
};

export const getUserFormation = async (userId: string, headquarterId: string) => {
  const res = await apiContable.get(`/worker/${userId}/${headquarterId}`);
  return res.data;
};

export const getRucInfo = async (ruc: string) => {
  const res = await apiContable.get(`/workers/migo/${ruc}`);
  return res.data;
};

export const getCommittee = async (headquarterId: string, kind: 'sexual_harassment' | 'general') => {
  const res = await apiContable.get(`/securityCommittee/${headquarterId}`, { params: { kind } });
  return res.data;
};

export const generatePrivateWorkersDeclaration = async (headquarterId: string, userId: string, body: Record<string, any>) => {
  const res = await apiContable.post(`/declaration/personal/${headquarterId}/${userId}`, body);
  return res.data;
};

export const generateHealthDeclaration = async (headquarterId: string, userId: string, body: Record<string, any>) => {
  const res = await apiContable.post(`/declaration/salud/${headquarterId}/${userId}`, body);
  return res.data;
};

export const updateUserData = async (userId: string, headquarterId: string, data: Record<string, any>) => {
  const res = await apiContable.put(`/workers/${userId}/${headquarterId}`, data);
  return res.data;
};

export const updatePassword = async (userId: string, body: Record<string, any>) => {
  const res = await apiContable.put(`/worker/password/${userId}`, body);
  return res.data;
};

export const forgotPassword = async (body: Record<string, any>) => {
  const res = await apiContable.post('/worker/recovery-password', body);
  return res.data;
};

export const recoveryPassword = async (body: Record<string, any>) => {
  const res = await apiContable.post('/worker/recovery-password/token', body);
  return res.data;
};

export const getMemorandum = async (headquarterId: string, params?: Record<string, any>) => {
  const res = await apiContable.get(`/memorandum/${headquarterId}`, { params });
  return res.data;
};

export const getVacations = async (userId: string, headquarterId: string, year: number) => {
  const res = await apiContable.get(`/vacation/${userId}/${headquarterId}/${year}`);
  return res.data;
};

export const getUsersByHeadquarter = async (headquarterId: string) => {
  const body = {
    'dataHeadquarters.headquarter': headquarterId,
    'dataHeadquarters.active': true,
  };
  const result = await apiContable.post('/workers/query', body);
  return result.data;
};

export const getAttendances = async (params: Record<string, string>) => {
  const res = await apiWorkers.get(`/worker/attendance/collaborators`, { params });
  return res.data;
};

export const getPermits = async (params: GetAttendancePermitsParamsProps) => {
  const res = await apiWorkers.get(`/worker/attendance/permits`, { params });
  return res.data;
};

export const createPermit = async (body: Record<string, unknown>) => {
  const res = await apiWorkers.post(`/worker/attendance/permits`, body);
  return res.data;
};

export const updatePermit = async (code: string, body: Record<string, unknown>) => {
  const res = await apiWorkers.put(`/worker/attendance/permits/${code}`, body);
  return res.data;
};

export const getPermitList = async () => {
  const res = await apiWorkers.get(`/worker/attendance/permits/types`);
  return res.data;
};

export const getDashboardData = async (params: Record<string, string>) => {
  const res = await apiWorkers.get('/worker/dashboard', { params });
  return res.data;
};

// INCOME TAXES
export const getIncomeTaxes = async (params: IncomeTaxReqParams): Promise<Array<IncomeTaxProps>> => {
  const res = await apiWorkers.get(`/worker/income-tax`, { params });
  return res.data;
};

export const postIncomeTax = async (body: Record<string, any>) => {
  const res = await apiWorkers.post(`/worker/income-tax`, body);
  return res.data;
};

export const putIncomeTax = async (body: Record<string, any>, taxId: string) => {
  const res = await apiWorkers.put(`/worker/income-tax/${taxId}`, body);
  return res.data;
};

export const deleteIncomeTax = async (taxId: string) => {
  const res = await apiWorkers.delete(`/worker/income-tax/${taxId}`);
  return res.data;
};

// CONSULTAR RUC & DNI
const defaultResponse = { t_doc: '', n_doc: '', nombre_o_razon_social: '', isError: false };
export const consultReniec = async (ndoc: string) => {
  const tdoc = ndoc.length === 8 ? 'dni' : ndoc.length === 11 ? 'ruc' : undefined;
  if (!tdoc) return defaultResponse;
  try {
    const res = await apiContable.post('/ruc', { type: tdoc, ruc: ndoc });

    return { t_doc: tdoc, n_doc: res.data.ruc, nombre_o_razon_social: res.data.nombre || res.data.nombre_o_razon_social, isError: false };
  } catch (error) {
    return { ...defaultResponse, isError: true };
  }
};

export const acceptPrivacyPolicy = async (data: Record<string, string>) => {
  const res = await apiContable.post('/workers/privacy-policy', data);
  return res.data;
};
